import { Fade, Grid, Typography, Chip } from '@material-ui/core'
import React, { useState } from 'react'
import useStyles from './styles';
import VizSensor from 'react-visibility-sensor';


const About = () => {
    const classes=useStyles();
    let [active, setActive] = useState(false);
    



    return (
        <>
        <a class={classes.anchor} id="mobileAbout"></a>
        <div className={classes.content} id={'about'} >
<VizSensor
partialVisibility
            onChange={(isVisible) => {
                if(isVisible){
                 setActive(isVisible);   
                }
                
            }}
        >
<Fade in={active} timeout={2000}>
<Grid container justify="center">

<Grid item xs={12} sm={12} md={8} lg={6}>

<Typography className={classes.title} variant="h2"gutterBottom><span className={classes.nav_numbers}> &#8544;.</span> About Me</Typography>
            <div className={classes.body}>

            <Typography variant='body1'>Hey there, I'm Moise, a passionate tech enthusiast and computer engineer with a knack for creativity and a love for building applications. My journey into coding began in 2015, and ever since, I've been fueled by a relentless curiosity to deepen my knowledge. For me, programming isn't just about writing lines of code; it's a means to tackle challenges and enhance daily life.</Typography>
            <br/>
            <Typography variant='body1'>I pride myself on being a self-starter and an innovative thinker. Alongside collaborating on various tech teams within the government of Canada, I've also embarked on exciting personal projects. Lately, I've delved into the captivating realms of Machine Learning and Artificial Intelligence, exploring their potential to streamline our routines.</Typography>
            <br/>
            <Typography variant="body1">Beyond the realm of technology, you'll often find me immersed in discussions about stocks and investments. I'm deeply passionate about financial literacy and believe in empowering individuals through education, an aspect often overlooked in traditional schooling.</Typography>
            <br/>
            <Typography variant="body1">Currently, I'm thriving as an Intermediate Backend Engineer at Common Wealth Retirement, a disruptive cloud-based retirement platform. Here, I channel my expertise to make retirement more accessible for fellow Canadians.</Typography>
            <br/>
            <Typography variant="body1">And when I'm not immersed in tech or financial realms, you can catch me on the tennis court, sharpening my skills and enjoying the thrill of the game.</Typography>
            <br/>
            <Typography variant="body1">Here's a glimpse of some of my strengths:</Typography>
            <Chip className={classes.chip} label="AWS Services" variant="outlined"/>
            <Chip className={classes.chip} label="Spring Boot" variant="outlined"/>
            <Chip className={classes.chip} label="React" variant="outlined"/>
            <Chip className={classes.chip} label="Python" variant="outlined"/>
            <Chip className={classes.chip} label="Java" variant="outlined"/>
            <Chip className={classes.chip} label="JavaScript (ES6+)" variant="outlined" />
            <Chip className={classes.chip} label="Node.js" variant="outlined"/>
            <Chip className={classes.chip} label="RESTful APIs" variant="outlined"/>
            <Chip className={classes.chip} label="Microservices Architecture" variant="outlined"/>
            <Chip className={classes.chip} label="Docker" variant="outlined"/>
            <Chip className={classes.chip} label="CI/CD Pipelines" variant="outlined"/>
            <Chip className={classes.chip} label="Database Design" variant="outlined"/>
            <Chip className={classes.chip} label="PostgreSQL" variant="outlined"/>
            <Chip className={classes.chip} label="NoSQL" variant="outlined"/>
            <Chip className={classes.chip} label="Agile" variant="outlined"/>
            <Chip className={classes.chip} label="Git" variant="outlined"/>
            <Chip className={classes.chip} label="Unit Testing" variant="outlined"/>
            <Chip className={classes.chip} label="Integration Testing" variant="outlined"/>
            </div>


</Grid>


</Grid>


</Fade>

        </VizSensor>


            

            
        </div></>
    )
}

export default About
