import { Box, Fade, Grid, Tab, Tabs, Typography } from '@material-ui/core'
import { styled } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react'
import useStyles from './styles';
import VizSensor from 'react-visibility-sensor';


const CustomTabs = styled(Tabs)({
  
    '& .MuiTabs-indicator': {
      backgroundColor: '#DDB450',
    },
  });

const CustomTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    width: 0,
    // color: 'rgba(0, 0, 0, 0.85)',
    color:"#BEBEBE",
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#DDB450',
      opacity: 1,
    },
    '&.Mui-selected': {
      color: '#DDB450',
      fontWeight: 'bold',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }));






const TabPanel=(props)=> {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        // id={`simple-tabpanel--${index}`}
        // aria-labelledby={`simple-tab-${index}`}

        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3,padding:0,paddingLeft:20 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }



TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

 const a11yProps=(index) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
      // id: `simple-tabpanel-${index}`,
      // 'aria-controls': `simple-tab-${index}`,
    };
  }



const Experience = () => {
    const classes=useStyles();
    const [value, setValue] = useState(0);
    let [active, setActive] = useState(false);

    const [state, setState] = useState({
      mobileView: false
    })
    const { mobileView } = state;

    useEffect(() => {
      const setResponsiveness = () => {
        return window.innerWidth < 900
          ? setState((prevState) => ({ ...prevState, mobileView: true }))
          : setState((prevState) => ({ ...prevState, mobileView: false }));
      };
      setResponsiveness();
      window.addEventListener("resize", () => setResponsiveness());
    }, []);




    const handleChange = (event, newValue) => {
        setValue(newValue);
      };



    return (
      <>
      <a class={classes.anchor} id="mobileExperience"></a>
        <div className={classes.content} id={"experience"}>
<VizSensor
partialVisibility

            onChange={(isVisible) => {
                if(isVisible){
                 setActive(isVisible);   
                }
                
                
            }}
        >
<Fade in={active} timeout={2000}>
           <Grid container justify="center" >

<Grid item xs={12} sm={12} md={8} lg={6}>

    <Typography className={classes.title} variant="h2" gutterBottom><span className={classes.nav_numbers}> &#8545;. </span>Experience</Typography>

</Grid>
<Grid item xs={12} sm={12} md={12} lg={8}>

    <Box
    sx={{height: 380,display:mobileView ? "block":"flex"}}
      // sx={{ flexGrow: 1, bgcolor: '#ffffff00', display: 'flex', height: 380,padding:0 }}
    >
        <CustomTabs
        orientation={mobileView?"horizontal":"vertical"}
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider', }}
        
      >
        <CustomTab label="CWR" {...a11yProps(0)} />
        <CustomTab label="IRCC" {...a11yProps(1)} />
        <CustomTab label="CBSA" {...a11yProps(2)} />
        <CustomTab label="GAC" {...a11yProps(3)} />
        <CustomTab label="TBS" {...a11yProps(4)} />
        <CustomTab label="Airbnb" {...a11yProps(5)} />
      </CustomTabs>


      <TabPanel value={value} index={0}>

      <div className={classes.body}>
      <Typography variant='subtitle1' className={classes.jobTitle}>Intermediate Backend Engineer <a href="https://www.commonwealthretirement.com/" target="_blank" className={classes.location} rel="noreferrer">@COMMON WEALTH RETIREMENT</a></Typography>
            <Typography variant='subtitle2'>July 2022 - Present | Toronto, ON</Typography>
            <ul>
            <li><Typography variant='body1'>Leveraged the Spring Boot framework for building robust and efficient backend services.</Typography></li>    
            <li><Typography variant='body1'>Designed database architecture based on Product requirements and executed SQL migration scripts.</Typography></li>    
            <li><Typography variant='body1'>Maintained architecture for RESTFUL APIs of key financial vehicles such as Annuities, LIRA, DPSP and DCPP.</Typography></li>    
            <li><Typography variant='body1'>Optimized the team's story-pointing system yielding to more accurate estimation and budgeting of features.</Typography></li>    
            <li><Typography variant='body1'>Conducted detailed code reviews on 30+ monthly pull requests in Java, Kotlin, and JavaScript, ensuring high-quality code and fewer bugs.</Typography></li>    
            <li><Typography variant='body1'>Utilized AWS CodeBuild for continuous integration, streamlining the development and deployment process.</Typography></li>    
            </ul>
        </div>
      </TabPanel>
 
      <TabPanel value={value} index={1}>

      <div className={classes.body}>
      <Typography variant='subtitle1' className={classes.jobTitle}>Application Programmer <a href="https://www.canada.ca/en/immigration-refugees-citizenship.html" target="_blank" className={classes.location} rel="noreferrer">@IMMIGRATION, REFUGEES AND CITIZENSHIP CANADA</a></Typography>
            <Typography variant='subtitle2'>September 2021 - July 2022 | Ottawa, ON</Typography>
            <ul>
            <li><Typography variant='body1'>Designed and implemented SQL queries for the execution of client data extract requests.</Typography></li>
            <li><Typography variant='body1'>Gathered 15+ impact assessments from team leads and produced costings for change requests submitted by our clients.</Typography></li>
            <li><Typography variant='body1'>Planned, tracked progress and collaborated on 2 projects using Azure DevOps.</Typography></li>
            <li><Typography variant='body1'>Interviewed, trained and evaluated 10+ junior ETL developers.</Typography></li>     
            </ul>
        </div>
      </TabPanel>



      <TabPanel value={value} index={2}>
      <div className={classes.body}>
      <Typography variant='subtitle1' className={classes.jobTitle}>Programmer <a  href='https://www.cbsa-asfc.gc.ca/menu-eng.html' target={"_blank"} className={classes.location} rel="noreferrer">@CANADA BORDER SERVICES AGENCY</a></Typography>
            <Typography variant='subtitle2'>May 2020 - September 2021 | Ottawa, ON</Typography>
            <ul>
            <li><Typography variant='body1'>Supported and tested a Java EE postal web application, ensuring robust and efficient backend functionality.</Typography></li>
<li><Typography variant='body1'>Developed and executed SQL scripts to maintain and update databases, enhancing data consistency and integrity across environments.</Typography></li>
<li><Typography variant='body1'>Integrated UTF-8 encoding into form logic to support Chinese characters, improving internationalization and user accessibility.</Typography></li>
<li><Typography variant='body1'>Utilized version control systems for efficient code management and deployment.</Typography></li>
           
            </ul>
            <Typography variant='subtitle1' className={classes.jobTitle}>Junior Programmer &#40;Co-op&#41; <a href='https://www.cbsa-asfc.gc.ca/menu-eng.html' target={"_blank"} className={classes.location} rel="noreferrer">@CANADA BORDER SERVICES AGENCY</a></Typography>
            <Typography variant='subtitle2'>September 2019 - December 2019 | Ottawa, ON</Typography>
            <ul>
<li><Typography variant='body1'>Applied knowledge of object-oriented programming to debug Java repositories.</Typography></li>
            <li><Typography variant='body1'>Improved the UI and UX of our message queue testing tool by making it more WCAG2 compliant.</Typography></li>
            </ul>
</div>
      </TabPanel>


      <TabPanel value={value} index={3}>
      <div className={classes.body}>
      <Typography variant='subtitle1' className={classes.jobTitle}>Software Quality Assurance Analyst &#40;Co-op&#41; <a href='https://www.international.gc.ca/global-affairs-affaires-mondiales/home-accueil.aspx?lang=eng' target="_blank" className={classes.location} rel="noreferrer">@GLOBAL AFFAIRS CANADA</a></Typography>
            <Typography variant='subtitle2'>January 2019 - August 2019 | Gatineau, QC</Typography>
            <ul>
            <li><Typography variant='body1'>Reviewed, analyzed and created test cases for end-to-end testing.</Typography></li>
<li><Typography variant='body1'>Identified 50+ automatable business processes and implemented their corresponding test scripts in Eclipse.</Typography></li>
<li><Typography variant='body1'>Provided project feedback, progress and updates on Trello.</Typography></li>
<li><Typography variant='body1'>Redesigned an automation framework to accommodate UAT test cases.</Typography></li>  
<li><Typography variant='body1'>Developed and executed automation test suites using Selenium, reducing manual testing time by 60% over an 8-month co-op period.</Typography></li>  
        
            </ul>
            </div>
      </TabPanel>




      <TabPanel value={value} index={4}>
      <div className={classes.body}>
      <Typography variant='subtitle1' className={classes.jobTitle}>Open Government Project Officer &#40;Co-op&#41; <a href='https://www.canada.ca/en/treasury-board-secretariat.html' target={"_blank"} className={classes.location} rel="noreferrer">@TREASURY BOARD OF CANADA SECRETARIAT</a></Typography>
            <Typography variant='subtitle2'>May 2018 - August 2018 | Ottawa, ON</Typography>
            <ul>
                <li><Typography variant='body1'>Researched on data visualization tools such as Plotly and RawGraphs.</Typography></li>
<li><Typography variant='body1'>Reported and troubleshot 300+ system bugs using JIRA.</Typography></li>
<li><Typography variant='body1'>Logged data metrics to better visualize our portal's efficacity and user engagement.</Typography></li>

            </ul>
            </div>
      </TabPanel>


      <TabPanel value={value} index={5}>
      <div className={classes.body}>
      <Typography variant='subtitle1' className={classes.jobTitle}>Airbnb Host <a href='https://www.airbnb.ca/users/show/88974127' target={"_blank"} className={classes.location} rel="noreferrer">@AIRBNB</a></Typography>
            <Typography variant='subtitle2'>August 2016 - Present | Multi-City</Typography>
            <ul>
                <li><Typography variant='body1'>Generated over $100,000 in revenue through effective marketing strategies, exceptional guest experiences, and maintaining high occupancy rates.</Typography></li>
<li><Typography variant='body1'>Implemented dynamic pricing strategies to maximize occupancy and revenue, analyzing market trends and adjusting rates accordingly to optimize profitability.</Typography></li>
<li><Typography variant='body1'>Received 120+ reviews with an overall rating close to 5 stars, reflecting exceptional guest satisfaction and positive experiences.</Typography></li>
<li><Typography variant='body1'>Enhanced the guest experience by coordinating partnerships with local businesses to provide exclusive discounts and special offers.</Typography></li>
          </ul>
            
            </div>
      </TabPanel>
      
    </Box>




    
            

           

            


            


            

            
            
            </Grid>


</Grid>


</Fade>
</VizSensor>


 
         

        </div></>
    )
}

export default Experience
